<template>
  <v-row align="center" justify="center" class="mt-10">
    <v-col cols="12" class="header mb-2 pl-2 py-4">
      <span style="font-size: 24px; font-weight: 400">{{
        $t("non_im.user_admin")
      }}</span>
    </v-col>
    <v-card outlined width="100vw" class="pa-4">
      <v-row>
        <v-col cols="4"
          ><v-text-field label="search" solo dense></v-text-field
        ></v-col>
        <v-spacer />
        <!-- <v-col cols="4" class="text-right">
          <v-btn dark color="#de5c8e" @click="opendialog()">
            <div v-if="EngLang">add&nbsp;</div>
            <div v-if="!EngLang">เพิ่ม</div>
            {{ $t("non_im.user_admin") }}</v-btn
          >
        </v-col> -->
      </v-row>

      <v-col cols="12">
        <v-data-table v-if="EngLang" :headers="headersEN" :items="items">
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.name_of_user }}</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ convertDate(item.createdAt) }} </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="
                item.status == 'Waiting for verification'
                  ? 'orange'
                  : item.status == 'Request verified'
                  ? 'blue'
                  : item.status == 'Approved'
                  ? 'green'
                  : item.status == 'Pending'
                  ? 'orange'
                  : 'red'
              "
              dark
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
            <v-icon @click="DeleteItem(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
        <!-- แยกภาษา -->
        <v-data-table v-if="!EngLang" :headers="headersTH" :items="items">
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ convertDate(item.createdAt) }} </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="
                item.status == 'Waiting for verification'
                  ? 'orange'
                  : item.status == 'Request verified'
                  ? 'blue'
                  : item.status == 'Approved'
                  ? 'green'
                  : item.status == 'Pending'
                  ? 'orange'
                  : 'red'
              "
              dark
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
            <v-icon @click="DeleteItem(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-card>
    <Dialog
      :dialog="dialog"
      :isEdit="isEdit"
      :form="form"
      :itemsaffiliation_faculty="itemsaffiliation_faculty"
      :EngLang="EngLang"
      @closeDialog="closeDialog()"
    />
    <!-- :affiliation_faculty="affiliation_faculty" -->
  </v-row>
</template>

<script>
import { Decode } from "@/services";
import moment from "moment";
import Dialog from "@/components/Dialog/DialogUser";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      dialog: false,
      isEdit: false,
      form: {
        firstname: "",
        lastname: "",
        give_name: "",
        middle_name: "",
        family_name: "",
        passport_number: "",
        password: "",
        status_as_chula: "",
        student_id_or_staff_id: "",
        title: "",
        username: "",
        user_type: "",
        mAffiliationFacultyId: null,
        mDepartmentId: null,
        createdAt: "",
      },
      // count: 1
      // createdAt: (...)
      // family_name: (...)
      // firstname: (...)
      // give_name: (...)
      // id: (...)
      // lastname: (...)
      // mAffiliationFacultyId: (...)
      // mDepartmentId: (...)
      // m_affiliation_faculty: (...)
      // m_department: (...)
      // middle_name: (...)
      // passport_number: (...)
      // password: (...)
      // schol: (...)
      // status_as_chula: (...)
      // student_id_or_staff_id: (...)
      // title: (...)
      // updatedAt: (...)
      // user_type: (...)
      // username: (...)

      userData: [],
      count: 0,
      EngLang: true,
      nationalityItems: [],
      headersTH: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "ชื่อผู้ใช้งาน", value: "username", align: "center" },
        { text: "ชื่อ", value: "give_name", align: "center" },
        { text: "นามสกุล", value: "family_name", align: "center" },
        { text: "วันที่เพิ่ม", value: "createdAt", align: "center" },
        // { text: "สถานะ", value: "status",width:300 },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      headersEN: [
        { text: "No.", value: "count", align: "center" },
        { text: "Username", value: "username", align: "center" },
        { text: "First Name", value: "give_name", align: "center" },
        { text: "Last Name", value: "family_name", align: "center" },
        { text: "Created At", value: "createdAt", align: "center" },
        // { text: "Status", value: "status",width:300 },
        { text: "Action", value: "action", align: "center" },
      ],
      items: [],
      itemsaffiliation_faculty: [],
    };
  },
  created() {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.EngLang = true;
      this.itemChangeLang = "lastname";
    } else if (lang == "th") {
      this.EngLang = false;
      this.itemChangeLang = "firstname";
    }
    this.getAffiliationFaculty();
    this.getAll();
  },

  methods: {
    async getAffiliationFaculty() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/affiliation_faculty`
      );
      console.log("response affiliation_faculty", response.data.data);
      this.itemsaffiliation_faculty = response.data.data;
    },
    async getAll() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/user/user_type_no_admin_and_superadmin`
        // http://localhost:8081/user/user_type_no_admin_and_superadmin
      );
      console.log("response", response.data.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    opendialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.isEdit = false;
      this.form = {};
      this.getAll();
    },
    convertDate(val) {
      return moment(val).format("DD/MM/yyyy");
    },
    goToEdit(item) {
      console.log("item", item);
      this.form = item;
      this.isEdit = true;
      this.dialog = true;
      // this.$router.push(`/Edit-Doc1?id=${id}`);
    },
    async DeleteItem(id) {
      this.$swal({
        text: this.EngLang
          ? "Do you want to delete ?"
          : "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.EngLang ? "Cancel" : "ยกเลิก",
        confirmButtonText: this.EngLang ? "Yes" : "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.userData = JSON.parse(
            Decode.decode(localStorage.getItem("userCUdata"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer ${this.userData.token}`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/user/${id}`,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll();
        }
      });
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #de5c8e;
  padding: 10px;

  color: white;
  border-radius: 5px;
}
</style>