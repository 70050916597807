<template>
  <v-dialog
    v-model="dialog"
    max-width="80%"
    @click:outside="cancel()"
    @keydown.esc="cancel()"
  >
    <v-card class="pa-4">
      <v-col cols="12" class="header mb-2 pl-2 py-4">
        <span style="font-size: 24px; font-weight: 400"
          >{{ $t("non_im.user_admin") }}
        </span>
      </v-col>
      <v-row>
        <v-col col="2" md="2">
          <div>Title</div>
        </v-col>
        <v-col col="4" md="4" class="mt-n2">
          <v-autocomplete
            v-if="!EngLang"
            v-model="form.title"
            :items="itemsTitle"
            item-text="textTH"
            item-value="value"
            solo
            dense
          ></v-autocomplete>
          <v-autocomplete
            v-if="EngLang"
            v-model="form.title"
            :items="itemsTitle"
            item-text="textEN"
            item-value="value"
            solo
            dense
          ></v-autocomplete>
        </v-col>
        <v-col col="2" md="2">
          <div>Given Name</div>
        </v-col>
        <v-col col="4" md="4" class="mt-n2">
          <v-text-field v-model="form.give_name" solo dense></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="2" md="2">
          <div>Middle Name</div>
        </v-col>
        <v-col col="4" md="4" class="mt-n2">
          <v-text-field v-model="form.middle_name" solo dense></v-text-field>
        </v-col>
        <v-col col="2" md="2">
          <div>Family Name</div>
        </v-col>
        <v-col col="4" md="4" class="mt-n2">
          <v-text-field v-model="form.family_name" solo dense></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="2" md="2">
          <div>Status at Chula</div>
        </v-col>
        <v-col col="4" md="4" class="mt-n2">
          <v-autocomplete
            v-model="form.status_as_chula"
            :items="itemStatusAtChula"
            :item-text="EngLang ? 'textEN' : 'textTH'"
            item-value="id"
            solo
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="4">
          <div>Student ID / Staff ID (If Applicable)</div>
        </v-col>
        <v-col cols="2" md="2" class="mt-n5 ml-n10">
          <v-radio-group v-model="isStudent" row solo dense>
            <v-radio :value="1" label="Yes"></v-radio>
            <v-radio :value="0" label="No"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" md="4" class="mt-n2 ml-n10">
          <v-text-field
            v-model="form.student_id_or_staff_id"
            :placeholder="
              isStudent == 1 ? 'Please enter (Student ID / Staff ID)' : ' - '
            "
            solo
            dense
            :disabled="isStudent == '0' ? true : false"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="2" md="2">
          <div>Passport number</div>
        </v-col>
        <v-col col="4" md="4" class="mt-n2">
          <v-text-field
            v-model="form.passport_number"
            solo
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="2" md="2">
          <div>Affiliation</div>
        </v-col>
        <v-col col="4" md="4" class="mt-n2">
          <!-- :item-text="EngLang ? 'name_en' : 'name_th'" -->
          <v-autocomplete
            v-model="form.mAffiliationFacultyId"
            :items="itemsaffiliation_faculty"
            item-value="id"
            :item-text="EngLang ? 'name_en' : 'name_th'"
            solo
            dense
            @change="getOneDepartments(form.mAffiliationFacultyId)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="4">
          <div>Department (If Applicable)</div>
        </v-col>
        <v-col cols="2" md="2" class="mt-n5 ml-n10">
          <v-radio-group v-model="isDepartment" row solo dense>
            <v-radio :value="1" label="Yes"></v-radio>
            <v-radio :value="0" label="No"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" md="4" class="mt-n2 ml-n10">
          <v-autocomplete
            v-model="form.mDepartmentId"
            :items="itemDepartments"
            item-value="id"
            :item-text="EngLang ? 'name_en' : 'name_th'"
            :placeholder="isDepartment == 1 ? 'Please enter department' : ' - '"
            solo
            dense
            :disabled="isDepartment == '0' ? true : false"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col col="2" md="2">
          <div>Telephone Number</div>
        </v-col>
        <v-col col="4" md="4" class="mt-n2">
          <v-text-field
            v-model="form.tel"
            :rules="rules.tel"
            :maxLength="10"
            counter="10"
            type="number"
            solo
            dense
          ></v-text-field>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col col="2" md="2">
          <div>Email</div>
        </v-col>
        <v-col col="4" md="4" class="mt-n2">
          <v-text-field
            v-model="form.username"
            :rules="rules.email"
            @blur="emailChange(email)"
            solo
            dense
            required
            type="email"
            :disabled="isEdit"
          ></v-text-field>
        </v-col>
        <span style="color: red" class="mt-2"
          >* กรณีที่เป็นนักศึกษา/บุคลากร ให้กรอกอีเมล ...@chula.ac.th
        </span>
      </v-row>
      <v-row v-if="!isEdit">
        <v-col col="2" md="2">
          <div>Password</div>
        </v-col>
        <v-col col="4" md="4" class="mt-n2">
          <v-text-field
            v-model="form.password"
            :rules="rules.passward"
            solo
            dense
            required
          ></v-text-field>
        </v-col>
        <span style="color: red" class="mt-2"
          >* กรณีที่เป็นนักศึกษา/บุคลากร
          ให้กรอกรหัสสําหรับล็อกอินเข้าใช้อีเมลจุฬา
        </span>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn
            v-if="isEdit"
            @click="editdata()"
            dark
            color="primary"
            class="mr-4"
            >แก้ไข
          </v-btn>
          <v-btn v-else @click="submit()" class="mr-4" dark color="primary"
            >บันทึก
          </v-btn>
          <v-btn @click="cancel()">ยกเลิก</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { Decode } from "@/services";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    form: Object,
    itemsaffiliation_faculty: Array,
    EngLang: Boolean,
  },
  data() {
    return {
      // dialog: false,
      isStudent: 0,
      isDepartment: 0,
      itemStatusAtChula: [
        {
          id: 1,
          textTH: "นักศึกษา",
          textEN: "Student",
        },
        {
          id: 2,
          textTH: "บุคลากร",
          textEN: "Personnel",
        },
      ],
      // form: {
      //   firstname: "",
      //   lastname: "",
      //   give_name: "",
      //   middle_name: "",
      //   family_name: "",
      //   passport_number: "",
      //   password: "",
      //   status_as_chula: "",
      //   student_id_or_staff_id: "",
      //   title: "",
      //   username: "",
      //   user_type: "",
      //   mAffiliationFacultyId: null,
      //   mDepartmentId: null,
      //   createdAt: "",
      // },
      // count: 1
      // createdAt: (...)
      // family_name: (...)
      // firstname: (...)
      // give_name: (...)
      // id: (...)
      // lastname: (...)
      // mAffiliationFacultyId: (...)
      // mDepartmentId: (...)
      // m_affiliation_faculty: (...)
      // m_department: (...)
      // middle_name: (...)
      // passport_number: (...)
      // password: (...)
      // schol: (...)
      // status_as_chula: (...)
      // student_id_or_staff_id: (...)
      // title: (...)
      // updatedAt: (...)
      // user_type: (...)
      // username: (...)
      isEmailChula: false,
      // itemsaffiliation_faculty: [],
      itemDepartments: [],
      itemsTitle: [
        {
          textTH: "นาย",
          textEN: "Mr.",
          value: "Mr",
        },
        {
          textTH: "นางสาว",
          textEN: "Ms.",
          value: "Ms",
        },
        {
          textTH: "นาง",
          textEN: "Mrs.",
          value: "Mrs",
        },
      ],

      rules: {
        tel: [
          (v) =>
            (v.length <= 10 && v.length >= 9) ||
            "Phone Number must be 9-10 charactors only.",
          (v) => !!v || "Please enter your phone number.",
        ],

        email: [(v) => !!v || "Please enter your email"],
        passward: [(v) => !!v || "Please enter your password"],
        passwardConfirm: [(v) => !!v || "Please enter password confirm"],
      },
    };
  },
  watch: {
    items: {
      handler(val) {
        if (this.isEdit === true) {
          //   this.getRewardById(val.id);
          //   console.log(val.id);
          // this.form =
        }
      },
      deeps: true,
    },
    "form.mAffiliationFacultyId"(val) {
      this.getOneDepartments(val);
    },
    "form.student_id_or_staff_id"(val) {
      if (val) {
        this.isStudent = 1;
      } else {
        this.isStudent = 0;
      }
    },

    "form.mDepartmentId"(val) {
      if (val) {
        this.isDepartment = 1;
      } else {
        this.isDepartment = 0;
      }
    },

    isDepartment(val) {
      if (this.isDepartment == 0) {
        this.form.mDepartmentId = null;
      }
      if (this.isDepartment == 1) {
        this.getOneDepartments(this.form.mAffiliationFacultyId);
      }
    },
  },
  methods: {
    async getOneDepartments(val) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/department?mAffiliationFacultyId=${val}`
      );
      console.log("itemDepartments", response.data.data);
      this.itemDepartments = response.data.data;
    },
    async submit() {
      // if (this.$refs.createForm.validate(true)) {
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      );
      console.log("thisuserData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const data = {
        ...this.form,
        user_type: "ADMIN",
        email: this.form.username,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/user`,
        data,
        // this.form,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: this.$t("status.submitSuccessMaster"),
          showConfirmButton: false,
          timer: 1500,
        });
        // this.form = {};
        // this.dialog = false;
        this.$emit("closeDialog");
      } else {
        this.$swal.fire({
          icon: "error",
          text: this.$t("status.submitUnsuccess"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    async editdata() {
      // if (this.$refs.createForm.validate(true)) {
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      );
      console.log("thisuserData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const data = {
        ...this.form,
        email: this.form.username,
      };
      const id = this.$route.query.id;
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/user/${this.form.id}`,
        data,
        // this.form,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: this.$t("status.submitSuccessMaster"),
          showConfirmButton: false,
          timer: 1500,
        });
        // this.form = {};
        // this.dialog = false;
        this.$emit("closeDialog");
      } else {
        this.$swal.fire({
          icon: "error",
          text: this.$t("status.submitUnsuccess"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    cancel() {
      this.$emit("closeDialog");
    },
  },
};
</script>